import React from 'react';
import { makeStyles } from '@mui/styles';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { Grid } from '@mui/material';
import { SubText } from '../HomeView.styles';

const useStyles = makeStyles({
  card: {
    margin: '10px',
    height: '100%',
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
});

const cardsData = [
  'Radiological Images are the results of the medical investigation tests from X Ray, CT Scans, MRI scans, etc.',
  'Today’s medical practice increasingly depends upon the the investigative tests and the outcomes. The outcome data/ or records are  very critical for the recommendations of the future.',
  'The goal here is to be empower the rightful owners of the records to be able to access this data from anywhere at anytime with right authorization.',
  'It would also enable tracking health trends and proactive health care for the family. It would also have potential to lower the overall costs of the healthcare.',
  'It would enable the doctors, hospitals and healthcare practitioners easy access, review, comment, prescribe and recommend from remote anywhere at anytime.',
  'The system would also enable easy share of the records with authorized users, doctors, hospitals and health practitioners.'
];

// const cardsData = [
//   'A secure vault to keep  all your health related documents.',
//   'Seamlessly and securely share your documents with only the people you want.',
//   'Doctors can now easily create and share reports and prescription with a few clicks.',
//   'Manage and claim  your insurance easily through MyHealthSafe.'
// ];

function SectionCards() {
  const classes = useStyles();

  return (
    <Grid container spacing={2} justifyContent="center">
      {cardsData.map((content, index) => (
        <Grid item xs={12} sm={6} md={3} lg={2} key={index}>
          <Card className={classes.card}>
            <CardContent className={classes.cardContent}>
              <SubText color="textSecondary">
                {content}
              </SubText>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};

export default SectionCards;
