import { Card, Typography } from '@mui/material';
import styled from 'styled-components';

export const ProductPageSecondCont = styled.div`
// border: 5px solid green;
padding: 30px;
background-color: #EBEBEB;
`

export const Headings = styled.div`
font-family: Poppins;
font-style: normal;
font-weight: 700;
font-size: 28px;
line-height: 54px;
padding: 30px;
color: #333333;
`

export const MuiCard = styled(Card)`
border-radius: 100% !important;
box-shadow: 10px 10px 8px #003D84 !important;

&:hover {
    border: 1px solid #003D84 !important;
   
  }

`

export const CircleText = styled(Typography)`
  font-family: Lato;
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 22px !important;
  letter-spacing: 0px;
  text-align: left;
`;