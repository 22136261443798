import { AboutUsPageFourthCont, Headings, SubHeadings } from "./Team.styles";
import SarvagnyaNarayan from '../../../images/Kishen.png';
import AmartyaPurushottam from '../../../images/AmartyaPurushottam.png';
import GangeyaPurushottam from '../../../images/GangeyaPurushottam.png';
import GyanesNarayan from '../../../images/GyanesNarayan.png';
import AnitaKhare from '../../../images/AnitaKhare.png';
import AchintayaSrivastava from '../../../images/AchintayaSrivastava.png';
import TuliSrivatsa from '../../../images/TuliSrivatsa.png';
import ShraddhaShivani from '../../../images/ShraddhaShivani.png';
import Grid from '@mui/material/Grid';
import { Box, Card, CardMedia } from '@mui/material';
import Footer from "../../../common/Footer/Footer";


export default function Team(){
    return (
        <>
        
        <AboutUsPageFourthCont>
                <center><Headings>Meet the team</Headings></center>
                <center style={{ padding: "10px"}}>
                    <Grid xs={12} md={8} lg={5}>
                        <SubHeadings style={{ padding: "10px 30px 10px 30px" }}>
                            Health eServ Founders & leadership group is responsible for managing the organization.
                            And ensuring that the vision of providing lifelong electronic health records is carried out.
                        </SubHeadings>
                        <SubHeadings style={{ padding: "20px 30px 30px 30px" }}>
                            At Health eServ, we are dedicated to providing our users with the best possible experience
                            when it comes to managing their health information. Our team is made up of experts in the
                            field of healthcare, technology, and user experience.
                        </SubHeadings>
                    </Grid>
                </center>
                <center><Headings>Founders</Headings></center>
                <Box mx={5} my={2}>
                    <div style={{ padding: "20px", cursor: 'not-allowed' }}>
                            <CardMedia>
                                <center>
                                    <img src={AmartyaPurushottam} />
                                    <img src={SarvagnyaNarayan} />
                                </center>
                            </CardMedia>
                            {/* <CardContent>
                                <Typography gutterBottom variant="h5" component="h2">
                                    Amartya Purushottam
                                </Typography>
                                </CardContent> */}

                        <br /><br />
                        <center><Headings>Promoters</Headings></center>
                        {/* <Grid container spacing={2}>
                            <Grid item>
                                <center>
                                        <GridCard>
                                            <img src={AmartyaPurushottam} style={{ height: 228, width: 300 }} />
                                            <Typography gutterBottom variant="h5" component="h2" style={{ fontFamily: 'Poppins', fontSize: '18px', fontWeight: '700', fontStyle: 'bold', lineHeight: '20px', color: '#000000' }}>
                                                Amartya Purushottam
                                            </Typography>
                                        </GridCard>
                                </center>
                            </Grid>
                            <Grid item>
                                <center>
                                        <GridCard>
                                            <img src={SarvagnyaNarayan} style={{ height: 228, width: 300 }} />
                                            <GridText>
                                                Sarvagnya Narayan
                                            </GridText>
                                        </GridCard>
                                </center>
                            </Grid>
                        </Grid> */}
                            <CardMedia>
                                <center>
                                    <img src={GangeyaPurushottam} />
                                    <img src={GyanesNarayan} />
                                    <img src={AnitaKhare} />

                                </center>
                            </CardMedia>
                            <CardMedia>
                                <center>
                                    <img src={AchintayaSrivastava} />
                                    <img src={TuliSrivatsa} />
                                    <img src={ShraddhaShivani} />

                                </center>
                            </CardMedia>
                    </div>
                </Box>
                <br /><br /><br />
                <Footer />
            </AboutUsPageFourthCont>
        </>
    )
}