import { Grid, Link } from '@mui/material';
import styled from 'styled-components';

export const ProductPageFourthCont = styled.div`
// border: 2px solid red;
margin-top: 20px;
margin-bottom: 20px;
`
export const FourthContHeadingText = styled.div`
font-family: Poppins;
font-style: normal;
font-weight: 400;
font-size: 28px;
line-height: 30px;
padding: 30px;
color: #333333;
// border: 2px solid violet;
// width: 300px;
`
export const FourthContLink = styled(Link)`
underline: none;
color: #003D84;
font-family: Poppins;
font-style: normal;
font-weight: 500;
font-size: 20px;
line-height: 25px;
padding: 30px;
text-decoration: none !important;
// border: 2px solid violet;
`

export const SubHeadings = styled.div`
font-family: poppins;
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 20px;
color: #444444;
padding: 30px;
// border: 2px solid violet;

`
export const WhatWeDoColGrid = styled(Grid)`
display: inline-block;
font-family: poppins;
variant: overline;
// max-width: 300px;
padding: 30px !important;
// margin-right: 20px ! important;
// border: 2px solid blue;

`
export const WhatWeDoColGridText = styled.div`
font-family: poppins;
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 20px;
color: #444444;
padding: 30px;
`