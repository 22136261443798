import styled from 'styled-components';

export const AboutUsPageSecondCont = styled.div`
// border: 5px solid blue;
padding-top: 30px;
`

export const ImgCont = styled.div`
// background-color: black;
// border: 2px solid green;
// padding: theme.spacing(1);
padding-top: 30px;
//  padding-bottom: 100px;
//  text-align: center;

`

export const TextCont = styled.div`

// padding-top: 100px;
// padding-bottom: 100px;
position: relative;
// border: 2px solid red;
padding: 30px;
`

export const Headings = styled.div`
    font-family: Lato;
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 54px;
    color: #333333;
    padding-bottom: 20px;
`

export const SubHeadings = styled.div`
    font-family: Lato;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #444444;
`