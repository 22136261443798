import styled from 'styled-components';
import { Grid } from '@mui/material';

export const TCh5 = styled.h5`
font-family: Calibri;
font-style: normal;
font-weight: 700;
font-size: 16px;
line-height: 20px;
color: grey;
font-family: Lato;
// border: 2px solid green;
// padding: 30px;
`

export const TCh4 = styled.h4`
font-family: Calibri;
font-style: normal;
font-weight: 800;
font-size: 16px;
line-height: 20px;
color: grey;
font-family: Lato;
// border: 2px solid green;
// padding: 30px;
`
