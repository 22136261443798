import AboutUs from "./AboutUs/AboutUs";
import Header from "./Header/Header";
import MissionAndVision from "./MissionAndVision/MissionAndVision";
import Team from "./Team/Team";

export default function(){
    return(
        <>
        <Header />
        <AboutUs />
        <MissionAndVision />
        <Team />
        </>
    )
}