import styled from 'styled-components';

export const AboutUsPageFourthCont = styled.div`
// border: 5px solid orange;
`
export const Headings = styled.h2`
    font-family: Lato;
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 54px;
    color: #333333;
    padding-bottom: 20px;
`

// export const GridCard = styled.div`
//     height: 300px;
//     width: 300px;
//     cursor: not-allowed;
//     border: 1px solid;
//     padding: 10px;
//     box-shadow: 5px 10px;
//     margin: 5px;
//     padding: 10px;
// `

// export const GridText = styled.h2`
//    fontFamily: Poppins;
//    fontSize: 18px;
//    height: 72px;
//    fontWeight: 700; 
//    fontStyle: bold; 
//    lineHeight: 20px; 
//    color: '#000000';
//    margin: 20px;
// `

export const SubHeadings = styled.div`
    font-family: Lato;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #444444;
    width: 1000px;
`