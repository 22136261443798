import { Container, Grid } from "@mui/material";
import { AboutUsPageThirdCont, Headings, ImgCont, SubHeadings, TextCont } from "./MissionAndVision.styles";
import MissionAndVisionImg from '../../../images/MissionAndVisionImg.png';


export default function MissionAndVision(){
    return(
        <>
          <AboutUsPageThirdCont>
                <Grid container style={{ paddingLeft: "100px"}}>
                    <Grid item xs={12} sm={5} md={12} lg={10} style={{ paddingLeft: "30px", padding: "30px", maxWidth: "700px"}}>
                        <TextCont>
                            <Headings>Mission and Vision</Headings>
                            <SubHeadings>
                                To develop such a leading-edge technology that integrates personal
                                health records with best-in-class medical and healthcare tools.
                            </SubHeadings><br/>
                            <SubHeadings>
                                Our vision is to make personal health data accessible and actionable Anytime,
                                anywhere by aggregating health information to help individuals pursue a healthier
                                lifestyle and get more value from their healthcare spending.
                            </SubHeadings>
                        </TextCont>
                    </Grid>
                    <Grid item xs={12} sm={7} md={12} lg={2}>
                        <ImgCont>
                            <img src={MissionAndVisionImg} />
                        </ImgCont>
                    </Grid>
                </Grid>
            </AboutUsPageThirdCont>
        </>
    )
}