import styled from 'styled-components';

interface Props {
    imgUrl : any
}

export const AboutUsPageFirstCont = styled.div<Props>`
padding: none !impotant;
margin: none !imporatant;
// border: 2px solid red;
background: url(${(props)=>props.imgUrl});
background-repeat: no-repeat;
background-size: cover;
// width: 100%;
height: 500px;
`