import { Container, Grid } from "@mui/material";
import { AboutUsPageSecondCont, Headings, ImgCont, SubHeadings, TextCont } from "./AboutUs.styles";
import AboutUsHealthEServeImg from '../../../images/AboutUsHealthEServeImg.png';


export default function AboutUs() {
    return (
        <>
            <AboutUsPageSecondCont>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={7} md={5} lg={5}>
                        <ImgCont>
                            <img src={AboutUsHealthEServeImg} />
                        </ImgCont>
                    </Grid>
                    <Grid item xs={12} sm={5} md={7} lg={7} style={{ padding: "30px" }}>

                        <TextCont>
                            <Headings>About us</Headings>
                            <SubHeadings> HealthServe is a health-tech company based out of Austin, Texas, that is dedicated to improving the healthcare experience for both patients and providers.The service leverages technology to make the healthcare system more efficient and accessible for anyone from anywhere.</SubHeadings><br />
                            <SubHeadings>Our team is made up of healthcare professionals, technology experts, and industry leaders who are all passionate about improving healthcare. We are constantly innovating and developing new solutions to help tackle some of the biggest challenges facing the healthcare industry today.</SubHeadings><br />
                            <SubHeadings>We are committed to creating high-quality, user-friendly products that empower patients to take control of their health and help providers deliver the best possible care. Our goal is to make a positive impact on the lives of as many people as possible and to be a trusted partner in the healthcare journey.</SubHeadings><br />
                            <SubHeadings>MyCareRec is a cloud based service hosted by the company to empower individual to store, manage and share his or her data in an easy, quick and secure fashion.</SubHeadings><br />

                        </TextCont>
                    </Grid>

                </Grid>
            </AboutUsPageSecondCont>
        </>
    )
}