import { Grid } from '@mui/material';
import styled from 'styled-components';

export const FooterContainer = styled.div`
    background-color: #262626;
    border-bottom: 2px solid white !important;
    padding: 60px 60px 10px 60px;
`;

export const FooterContainerGrid = styled(Grid)`
    border-top: 2px solid white !important;
    border-bottom: 2px solid white !important;
`;

export const FooterItem = styled(Grid)`
    padding: 60px 0px !important;
    text-align: left;
    ${props => props.theme.breakpoints.down("sm")} {
        text-align: center;
        padding: 20px 0px !important;
    }
`;

export const BrandIcon = styled.img`
    cursor: pointer;
    margin-right: 30px;
`;

export const FooterTitle = styled.div`
    font-family: Lato !important;
    padding-bottom: 20px !important;
    font-size: 22px !important;
    font-weight: 400 !important;
    line-height: 19px !important;
    letter-spacing: -0.02em !important;
    color: #ffffff !important;
`;

export const FooterContent = styled.div`
    font-family: Lato !important;
    font-size: 16px !important;
    padding-bottom: 10px !important;
    font-weight: 400 !important;
    line-height: 19px !important;
    letter-spacing: -0.02em !important;
    color: #ffffff !important;
`;

export const FooterBrandContainer = styled.div`
    padding: 20px 0px !important;
    display: flex;
    ${props => props.theme.breakpoints.down("sm")} {
        // height: 40px;
        text-align: center;
        display: block;
    }
`;

export const Columns = styled.div`
    display: inline-flex;
`;

export const TextField = styled.span`
    padding: 10px;
    color: white;
    font-family: Lato;
    font-weight: 500;
    font-size: 16px;
`;
