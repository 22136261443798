import { useTheme } from "@mui/material/styles";
import { ThemeProvider } from 'styled-components';
import {
    HomePageImageContainer,
    HomePageFirstContText, KnowMoreBtn, LinearGradient, HeaderSubheading
} from '../../Home.styles';
import HomePageImg from '../../../images/HomePageImg.png';
import Navbar from '../../../common/Navbar/Navbar';

const Header = () => {
    const muiTheme = useTheme();

    return (

        <>
            <HomePageImageContainer imgUrl={HomePageImg}>
                <LinearGradient >
                    <Navbar />
                    <ThemeProvider theme={muiTheme}>
                        <center>
                            <HomePageFirstContText>
                                <div>
                                    <center>Get full access and control of your health data Anytime, Anywhere</center>
                                </div>
                                {/* <div><KnowMoreBtn>Know more</KnowMoreBtn></div> */}
                            </HomePageFirstContText>
                            <center><HeaderSubheading>Ensure children’s good health</HeaderSubheading></center>
                            <center><HeaderSubheading style={{paddingBottom: 60}}>Provide timely care to your parents</HeaderSubheading></center>
                        </center>
                    </ThemeProvider>
                </LinearGradient>
            </HomePageImageContainer>
        </>
    )
}

export default Header;