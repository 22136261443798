import styled from 'styled-components';
import { Grid } from '@mui/material';


export const MyCareRecColmGridTextContainer = styled.div`
font-family: Lato;
font-style: normal;
font-weight: 700;
font-size: 14px;
line-height: 20px;
max-width: 150px;
color: #263238;
font-family: Lato;
// border: 2px solid green;
// padding: 30px;
`

export const WhatWeDoContainer = styled.div`
background-color:#EBEBEB;
padding: 30px;
// border: 2px solid pink;
`
export const WhatWeDoColGrid = styled(Grid)`
display: inline-block;
font-family: Lato;
variant: overline;
max-width: 300px;
padding: 30px !important;
// margin-right: 20px ! important;
// border: 2px solid blue;

`
export const WhatWeDoColGridText = styled.div`
font-family: Lato;
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 20px;
color: #444444;
padding: 30px;
`

export const Item2 = styled.div`
padding-top: 100px;
// padding-bottom: 100px;
position: relative;
`

export const Item1 = styled.div`
// background-color: black;
// color: red;
// padding: theme.spacing(1);
padding: 30px;

`
interface Props {
    imgUrl: any
}

export const HomePageImageContainer = styled.div<Props>`
// padding: 50px;
// // border: 2px solid red;
// background: url(${(props) => props.imgUrl}), linear-gradient(180deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.426) 60.44%, rgba(0, 0, 0, 0) 100%);
// background-repeat: no-repeat;
// background-size: cover;
// // background: linear-gradient(180deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.426) 60.44%, rgba(0, 0, 0, 0) 100%);
// // width: 500%;
// // height: 300px;

background: url(${(props) => props.imgUrl});
background-repeat: no-repeat !important;
background-size: cover;
`

export const LinearGradient = styled.div`
background: linear-gradient(180deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0.426) 76.44%, rgba(0, 0, 0, 0) 100%);
`

export const HeaderSubheading = styled.h4`
font-family: Lato;
font-style: normal;
font-weight: 700;
font-size: 28px;
color: white;
text-align: center;
max-width: 800px;`

export const HomePageFirstContText = styled(Grid)`
font-family: Lato;
font-style: normal;
font-weight: 700;
font-size: 36px;
line-height: 54px;
padding: 50px 50px 20px 50px;
color: white;
text-align: center;
max-width: 800px;
// border: 2px solid red;

${props => props.theme.breakpoints.down("sm")} {
    font-weight: 600;
font-size: 28px;
line-height: 46px;
}
`
export const KnowMoreBtn = styled.button`
width: 180px;
padding: 10px;
// height: 20px;
font-family: Lato;
font-style: normal;
font-weight: 600;
font-size: 14px;
line-height: 20px;
border-radius: 20px;
background-color: #BE6A1D;
color: white;
border: none !important;
`

export const AdvantagesOfMyCareRecMainCont = styled.div`
padding: 30px;
// border: 2px solid blue;
`
export const AdvantagesOfMyCareRecImgCont = styled.img`
// border: 2px solid yellow;
// display: inline-flex;
text-align: center;
`
export const HomePageText = styled(Grid)`
    padding: 60px 0px !important;
    text-align: left;
    ${props => props.theme.breakpoints.down("sm")} {
        text-align: center;
        padding: 20px 0px !important;
    }
`
