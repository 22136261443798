
//@ts-ignore
import React from 'react';
import { Headings } from '../../../common/styledComponents/Headings.styles';
import SectionCards from './ServiceCards';
import { WhatWeDoContainer } from '../HomeView.styles';

function WhatWeDoSection() {

  return (
    <WhatWeDoContainer>
      <Headings>What we do</Headings>
      <SectionCards />

    </WhatWeDoContainer>
  );
};

export default WhatWeDoSection;
