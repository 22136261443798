
import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

import MyCareRec from '../../images/MyCareRec.png';
import { Container, Tabs } from './Navbar.styles';
import { Link, Outlet } from 'react-router-dom';

interface Props {
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window?: () => Window;
}

const drawerWidth = 140;
const navItems = ['myCareRec', 'products', 'contact'];



export default function Navbar(props: Props) {
    const { window } = props;
    const [mobileOpen, setMobileOpen] = React.useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen((prevState) => !prevState);
    };

    const drawer = (
        <div onClick={handleDrawerToggle} style={{ textAlign: 'center' }}>
            {/* <Typography variant="h6" sx={{ my: 2 }}>
                MUI
            </Typography> */}
            <Divider />
            <List>
                {navItems.map((item) => (
                    <ListItem key={item} >
                        <ListItemButton sx={{ textAlign: 'center' }}>
                            <ListItemText primary={item} />
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
        </div>
    );

    const container = window !== undefined ? () => window().document.body : undefined;

    return (<>
        <AppBar component="nav" style={{ position: "static", backgroundColor: "transparent", boxShadow: "none"}}>
            <Container style={{height: "60px", overflow: "hidden"}}>
               
                <Typography
                    variant="h6"
                    component="div"
                    sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}
                    style={{ display: "inline-block", padding: "10px", marginLeft: "15px", marginTop: "10px" }}
                >
                    <Link to="/"><img src={MyCareRec} style={{ cursor: "pointer"}} height={25} width={140} /></Link>
                </Typography>
                <Box style={{ display: 'inline-block', float: 'right', padding: "10px", marginRight: "15px", marginTop: "5px" }}>
                    <Link style={{textDecoration: 'none'}} to="/myCareRec"><Tabs>About us</Tabs></Link>
                    <Link style={{textDecoration: 'none'}} to="/products"><Tabs>Services</Tabs></Link>
                    <Link style={{textDecoration: 'none'}} to="/contactus"><Tabs>Contact us</Tabs></Link>

                </Box>
                <IconButton
                        // color="inherit"
                        aria-label="open drawer"
                        edge="end"
                        onClick={handleDrawerToggle}
                        sx={{ mr: 2, display: { sm: 'none' } }}
                        style={{verticalAlign: "baseline"}}
                    >
                        <MenuIcon />
                    </IconButton>
                <Outlet />
            </Container>
        </AppBar>
        <Box component="nav">
        <Drawer
                    container={container}
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                        display: { xs: 'block', sm: 'none' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                    }}
                >
                    {drawer}
                </Drawer>
        </Box>
    </>
    );
}