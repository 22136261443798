import MyCareRecForDoctors from '../../../images/MyCareRecForDoctors.png';
import MyCareRecForPatients from '../../../images/MyCareRecForPatients.png';
import { Grid, Paper } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { EcoSystemContainer, EcoSystemImg, EcoSystemImgGrid, EcoSystemSubHeading, EcoSystemHeading } from './EcoSystem.styles';
import { ThemeProvider } from 'styled-components';
import { useTheme } from "@mui/material/styles";



const useStyles = makeStyles({
    paper: {
        display: 'flex',
        flexDirection: 'column',
        // alignItems: 'center',
        padding: '16px',
        minHeight: '200px',
        boxShadow: 'none !important'
    },
    img: {
        width: '100%',
        marginBottom: '16px',
    },
});

const data = [
    {
        img: MyCareRecForDoctors,
        content: 'MyCareRec for Doctors',
        content1: 'Doctors can manage appointments, access the patients history and write prescriptions and tests.'
    },
    {
        img: MyCareRecForPatients,
        content: 'MyCareRec for Users',
        content1: 'Users can store and share their prescriptions and tests easily with doctors and other stakeholders.'
    }
];




export default function EcoSystem() {

    const classes = useStyles();
    const muiTheme = useTheme();


    return (
        <>
            <EcoSystemContainer>
                {/* <center><EcoSystemMainHeadings>MyCareRec provides two types of services</EcoSystemMainHeadings></center>
                <center><EcoSystemMainSubHeadings>MyCareRec for Users</EcoSystemMainSubHeadings></center>
                <center><EcoSystemMainSubHeadings>MyCareRec for doctors, hospitals and health practitioners</EcoSystemMainSubHeadings></center> */}
                <Grid container spacing={8} justifyContent="center" style={{ paddingTop: "60px" }}>
                    {data.map((item, index) => (
                        <ThemeProvider theme={muiTheme}>
                            <EcoSystemImgGrid item xs={12} sm={6} md={6} lg={6} key={index}>
                                <Paper className={classes.paper} style={{ border: "1px solid #dadada", borderRadius: "5px" }}>
                                    {/* <img className={classes.img} src={item.img} alt="" /> */}

                                    <EcoSystemHeading variant="body1">{item.content}</EcoSystemHeading>
                                    <br />
                                    <EcoSystemImg className={classes.img} src={item.img} alt="" />
                                    <EcoSystemSubHeading variant="body1">{item.content1}</EcoSystemSubHeading>

                                </Paper>
                            </EcoSystemImgGrid>
                        </ThemeProvider>
                    ))}
                </Grid>
            </EcoSystemContainer>
        </>
    )
}