import styled from "styled-components";

export const AboutUsPageThirdCont = styled.div`
// border: 5px solid violet;
   background-color: #EBEBEB;
   padding : 50px;
`

export const ImgCont = styled.div`
// padding: 30px;
padding-left: 250px;
`

export const TextCont = styled.div`

// padding-top: 100px;
// padding-bottom: 100px;
position: relative;
// border: 2px solid red;
`

export const Headings = styled.div`
    font-family: Lato;
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 54px;
    color: #333333;
    padding-bottom: 20px;
`

export const SubHeadings = styled.div`
    font-family: Lato;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #444444;
`