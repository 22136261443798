import styled from "styled-components";
import { Typography } from "@mui/material";

export const Root = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 16px;
`;

export const Column = styled.div`
  flex-basis: calc(25% - 16px);
`;

export const WhatWeDoContainer = styled.div`
  background-color: #EBEBEB;
  padding: 60px 80px;
`;

export const AdvantagesContainer = styled.div`
  padding: 60px;
`;

export const AdvantagesImage = styled.img`
  padding: 10px;
`;

export const SubText = styled(Typography)`
  font-family: Lato;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
`;
 