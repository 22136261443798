import styled from 'styled-components';

export const Headings = styled.div`
    font-family: Lato;
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 54px;
    color: #333333;
    padding-bottom: 20px;
`

export const SubHeadings = styled.div`
    font-family: Lato;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #444444;
`