import { Grid, Paper } from "@mui/material";
import {
    FourthContHeadingText, FourthContLink, GridText, ProductPageFourthCont, SubHeadings
} from "./Doctors.styles";
import { makeStyles } from '@mui/styles';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { SubText } from "../../HomeView/HomeView.styles";

const useStyles = makeStyles({
    card: {
        margin: '10px',
        height: '100%',
    },
    cardContent: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
    },
});


const FourthContGridText = [
    {
        text: 'Create',
        subtext: 'Easily create prescriptions that can shared with the patients directly.',
    },
    {
        text: 'Chat',
        subtext: 'Chat with the patients to resolve their queries quickly.',
    },
    {
        text: 'Access',
        subtext: 'Access all patients medical history for better diagnosis.',
    },
    {
        text: 'Manage',
        subtext: 'Add new patients and manage your network seamlessly.',
    }
]



export default function Doctors() {

    const classes = useStyles();

    return (
        <>

            <br />
            <ProductPageFourthCont>

                <Grid container spacing={2} style={{}}>
                    <Grid item xs={12} sm={4} md={4} lg={4} width={"none !important"} style={{ padding: "70px 30px 30px 30px", backgroundColor: "#EBEBEB" }}>
                        <FourthContHeadingText><b>MyCareRec</b> for <b>Doctors</b></FourthContHeadingText>
                        <SubHeadings>creating prescriptions made easy</SubHeadings>
                        <FourthContLink href='https://dev.mycarerec.com'>Go to MyCareRec for Doctors</FourthContLink>
                    </Grid>

                    <Grid item xs={12} sm={6} md={6} lg={1} style={{ borderTop: "1px solid #dadada", borderBottom: "1px solid #dadada" }}></Grid>
                    <Grid item lg={7} xs={12} style={{ padding: "40px", justifyContent: "center", borderTop: "1px solid #dadada", borderBottom: "1px solid #dadada" }}>
                        <Grid container>
                            {FourthContGridText.map((content, index) => (
                                <Grid item xs={12} sm={6} md={6} lg={5} style={{ padding: "20px 40px 20px 40px" }}>

                                    <Card className={classes.card} key={index}>
                                        <CardContent className={classes.cardContent}>
                                            <GridText>{content.text}</GridText>
                                            <SubText color="textSecondary">
                                                {content.subtext}
                                            </SubText>
                                            <div style={{ flex: 1 }} />
                                        </CardContent>
                                    </Card>
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>

                </Grid>


            </ProductPageFourthCont>
        </>
    )
}