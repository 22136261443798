import styled from 'styled-components';

interface Props {
    imgUrl : any
}

export const ContactPageImageContainer = styled.div<Props>`
padding: 80px;
margin: none !important;
// border: 2px solid white;
// margin: 100px;
background: url(${(props)=>props.imgUrl});
background-repeat: no-repeat;
background-size: cover;
// width: 500%;
height: 500px;
`

export const SubmitBtn = styled.button`
width: 180px;
padding: 10px;
font-family: Lato;
font-style: normal;
font-weight: 600;
font-size: 14px;
line-height: 20px;
border-radius: 20px;
background-color: #BE6A1D;
color: white;
border: none !important;
cursor: pointer;
`