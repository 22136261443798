import { AboutUsPageFirstCont } from "./Header.styles";
import aboutUsPageImg from '../../../images/aboutUsPageImg.png';
import Navbar from "../../../common/Navbar/Navbar";


const Header = () => {

    return (

        <>
           <AboutUsPageFirstCont imgUrl={aboutUsPageImg}>
            <Navbar />
            </AboutUsPageFirstCont>
        </>
    )
}

export default Header;