import { Grid, Typography } from '@mui/material';
import styled from 'styled-components';

export const EcoSystemContainer = styled.div`
  padding: 60px;
`;

export const EcoSystemMainHeadings = styled.div`
    font-family: Lato;
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 54px;
    color: #333333;
    // padding: 10px;
`

export const EcoSystemMainSubHeadings = styled.div`
    font-family: Lato;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 26px;
    color: #444444;
    // padding: 10px;
`

export const EcoSystemHeading = styled(Typography)`
  font-family: Lato;
  font-size: 24px !important;
  font-weight: 700 !important;
  line-height: 54px !important;
  letter-spacing: 0px;
  text-align: left !important;
  // padding-top: 5px !important; 
  padding-bottom: 5px !important; 

`;

export const EcoSystemSubHeading = styled(Typography)`
  font-family: Lato;
  font-size: 14px ;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
  padding-top: 20px !important; 
  padding-bottom: 20px !important; 
`;

export const EcoSystemImgGrid = styled(Grid)`
width: 80% !important;
${props => props.theme.breakpoints.down("sm")} {
    width: 60% important;
    // height: 250px !important;
}
`

export const EcoSystemImg = styled.img`
width: 400px;
height: 300px;
${props => props.theme.breakpoints.down("sm")} {
    width: 60% important;
    height: 250px !important;
}
`