import { Headings, Images, SubHeadings1 } from './MyCareRec.styles';
import { MyCareRecColmGridTextContainer, Item1, Item2, WhatWeDoColGrid } from './MyCareRec.styles';
import MacBook from '../../../images/MacBook.png';
import MyCareRec from '../../../images/MyCareRec.png';
import Iphone from '../../../images/Iphone.png';
import GooglePlay from '../../../images/GooglePlay.png';
import AppStore from '../../../images/AppStore.png';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { ThemeProvider } from 'styled-components';
import { useTheme } from "@mui/material/styles";



const MyCareRecSection = () => {

    const muiTheme = useTheme();


    const MyCareRecColmGridText1 = [
        'Peace of mind - Records available anytime / anywhere',
        'Track Doctor & Hospital Visits',
        'Accurate Diagnosis',
        'Save New Costs'
    ]

    const MyCareRecColmGridText2 = [
        'View Health Trends',
        'Ensure children’s good health',
        'Proactive care for parents',
        'Easy share with Doctors and  Hospitals'

    ]

    return (
        <>
            <Box style={{ paddingTop: "30px", paddingBottom: "30px" }}>
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={12} md={7} lg={7} >
                        <Item1>
                            <Headings><img src={MyCareRec} /></Headings>
                            <SubHeadings1>Welcome to myCareRec, the ultimate personal healthcare data vault service. This service provides you to manage and secure radiological images and associate health data for you and your family.</SubHeadings1>
                            <SubHeadings1>You can create your and family’s own private health vault. Store, Maintain and manage  the records of
                                your doctor and hospital visits, radiological images, lab reports,  prescriptions and all  advised medication.</SubHeadings1>
                            <SubHeadings1>Share and provide access to your and family’s health data with authorized  doctors,  hospital , health practitioners very quickly and smoothly.</SubHeadings1>
                            <SubHeadings1> Allow doctors, hospitals and health professionals to view, comment , recommend and consult on scheduled time in person or remotely from anywhere.
                            </SubHeadings1>
                            <Grid container spacing={0}>
                                <Grid item  >
                                    <Grid container spacing={0}>
                                        {MyCareRecColmGridText1.map((value) => (
                                            <WhatWeDoColGrid key={value} style={{ display: "block" }} item>
                                                <MyCareRecColmGridTextContainer>{value}</MyCareRecColmGridTextContainer>

                                            </WhatWeDoColGrid>
                                        ))}
                                    </Grid>
                                    <Grid container spacing={0}>
                                        {MyCareRecColmGridText2.map((value) => (
                                            <WhatWeDoColGrid key={value} style={{ display: "block" }} item>
                                                <MyCareRecColmGridTextContainer>{value}</MyCareRecColmGridTextContainer>
                                            </WhatWeDoColGrid>
                                        ))}
                                    </Grid>
                                    <div style={{ display: "flex", padding: "30px" }}>
                                        <a href="https://play.google.com/store/apps/details?id=com.mycarerec"><img src={GooglePlay} /></a>
                                        <a> <img src={AppStore} /></a >
                                    </div>
                                </Grid>
                            </Grid>
                        </Item1>
                    </Grid>
                    {/* <Grid item xs={12} md={5} lg={5} style={{}}> */}
                    <ThemeProvider theme={muiTheme}>
                        <Images item lg={5} md={5} sm={12} xs={12} >
                            <Item2 >
                                <img style={{ width: "100%" }} src={MacBook} />
                                <div style={{ position: "absolute", top: "40px", bottom: "30px", left: "20px", paddingTop: "100px" }}>
                                    <img style={{ maxHeight: "100%" }} src={Iphone} />
                                </div>
                            </Item2>
                        </Images>
                    </ThemeProvider>
                    {/* </Grid> */}
                </Grid>
            </Box>
        </>
    )
}

export default MyCareRecSection;