import { Grid } from "@mui/material";
import {
    FourthContHeadingText, ProductPageFourthCont, SubHeadings
} from './Patients.styles';
import { makeStyles } from '@mui/styles';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { SubText } from "../../HomeView/HomeView.styles";
import GooglePlay from '../../../images/GooglePlay.png';
import AppStore from '../../../images/AppStore.png';
import Footer from "../../../common/Footer/Footer";
import { Headings } from "../Record/Record.styles";
import { GridText } from "../Doctors/Doctors.styles";

const useStyles = makeStyles({
    card: {
        margin: '10px',
        height: '100%',
    },
    cardContent: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
    },
});

const FourthContGridText = [
    {
        text: 'Store',
        subtext: 'A secure vault to keep  all your health related documents.',
    },
    {
        text: 'Share',
        subtext: 'Seamlessly and securely share your documents with only the people you want.',
    },
    {
        text: 'Manage',
        subtext: 'Manage your and your family’s prescriptions and reports.',
    },
    {
        text: 'Claim',
        subtext: 'Easily claim  your insurance by sharing your documents through MyCareRec.',
    }
]



export default function Patients() {

    const classes = useStyles();

    return (
        <>
            <br />
            <ProductPageFourthCont>
                <Grid container spacing={2} style={{}}><Grid item xs={12} sm={6} md={6} lg={1} style={{ borderTop: "1px solid #dadada", borderBottom: "1px solid #dadada" }}></Grid>
                    
                    <Grid item lg={6} xs={12} style={{ padding: "40px", justifyContent: "center", borderTop: "1px solid #dadada", borderBottom: "1px solid #dadada" }}>
                        <Grid container>
                            {FourthContGridText.map((content, index) => (
                                <Grid item xs={12} sm={6} md={6} lg={6} style={{ padding: "20px 40px 20px 40px" }}>

                                    <Card className={classes.card} key={index}>
                                        <CardContent className={classes.cardContent}>
                                        <GridText>{content.text}</GridText>
                                            <SubText color="textSecondary">
                                                {content.subtext}
                                            </SubText>
                                            <div style={{ flex: 1 }} />
                                        </CardContent>
                                    </Card>
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={1} style={{ borderTop: "1px solid #dadada", borderBottom: "1px solid #dadada" }}></Grid>
                    <Grid item xs={12} lg={4} width={"none !important"} style={{ padding: "50px 30px 30px 30px", backgroundColor: "#EBEBEB" }}>
                        <FourthContHeadingText><b>MyCareRec</b> for <b>Patients</b></FourthContHeadingText>
                        <SubHeadings style={{}}>managing prescriptions made easy</SubHeadings>
                        <Headings style={{}}>Download App</Headings>
                        <div style={{ display: "flex", padding: "5px 0px 0px 30px" }}>
                            <a href="https://play.google.com/store/apps/details?id=com.mycarerec"><img src={GooglePlay} /></a>
                            <a ><img src={AppStore} /></a>
                        </div>
                    </Grid>
                </Grid>
            </ProductPageFourthCont>
            <Footer />
        </>
    )
}