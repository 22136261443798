import { Grid } from '@mui/material';
import styled from 'styled-components';

export const ProductPageFirstCont = styled.div`
// border: 5px solid blue;
padding-top: 30px;
`

export const FirstContTextPart = styled.div`
// background-color: black;
padding: 30px;
`

export const MyCareRecHeading = styled.div`
padding: 30px;
margin-top: 10px;
// border: 5px solid blue;
`

export const MyCareRecHeading1 = styled.div`
padding: 10px 30px 30px 30px;
margin-top: 0px;
// border: 5px solid blue;
`

export const MyCareRecSubHeading = styled.div`
font-family: poppins;
font-style: normal;
font-weight: 600;
max-width: 400px;
font-size: 18px;
line-height: 26px;
color: #444444;
padding: 30px;
// border: 5px solid yellow;
`
export const  ProductPageFirstContImg = styled.div`
// padding-top: 100px;
// padding-bottom: 100px;
position: relative;

`

export const ImageItem = styled(Grid)`
   width: 100%;
    ${props => props.theme.breakpoints.down("sm")} {
        width: 90%
    }
    ${props => props.theme.breakpoints.down("md")} {
        padding-top: 40px;
    }
`;
