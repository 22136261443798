import { FirstContTextPart, ImageItem, MyCareRecHeading, MyCareRecHeading1, MyCareRecSubHeading, ProductPageFirstCont, ProductPageFirstContImg } from "./Header.styles";
import { Grid } from '@mui/material';
import { useTheme } from "@mui/material/styles";
import { ThemeProvider } from 'styled-components';
import ProductsMacBook from '../../../images/ProductsMacBook.png';
import MyCareRec from '../../../images/MyCareRec.png';
import Iphone from '../../../images/Iphone.png';
import Navbar from "../../../common/Navbar/Navbar";
import { EcoSystemMainHeadings, EcoSystemMainSubHeadings } from "../EcoSystem/EcoSystem.styles";



export default function Header() {

    const muiTheme = useTheme();

    return (
        <>
            <Navbar />
            <ProductPageFirstCont>
                <Grid container >
                    <Grid item lg={6} md={6} sm={6} xs={12}>
                        <FirstContTextPart style={{}}>
                            <MyCareRecHeading><img src={MyCareRec} /></MyCareRecHeading>
                            <MyCareRecSubHeading>MyCareRec provides two types of services</MyCareRecSubHeading>
                            <MyCareRecHeading1>MyCareRec for Users</MyCareRecHeading1>
                            <MyCareRecHeading1>MyCareRec for Doctors</MyCareRecHeading1>

                        </FirstContTextPart>
                    </Grid>
                    <ThemeProvider theme={muiTheme}>

                        <ImageItem item lg={6} md={6} sm={6} xs={12}>
                            <ProductPageFirstContImg style={{}}>
                                <img style={{ width: "100%" }} src={ProductsMacBook} />
                                <div style={{ position: "absolute", top: "30px", bottom: "30px", left: "20px" }}>
                                    <img style={{ maxHeight: "100%" }} src={Iphone} />
                                </div>
                            </ProductPageFirstContImg>
                        </ImageItem>
                    </ThemeProvider >
                </Grid>
            </ProductPageFirstCont>
        </>
    )
}