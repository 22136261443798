import Doctors from "./Doctors/Doctors";
import EcoSystem from "./EcoSystem/EcoSystem";
import Header from "./Header/Header";
import Patients from "./Patients/Patients";
import Record from "./Record/Record";

export default function MyCareRec() {
    return (
        <>
            <Header />
            <Record/>
            <EcoSystem />
            <Doctors />
            <Patients />
        </>
    )
}