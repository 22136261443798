import MyCareRec from '../../images/MyCareRec.png';
import { BrandIcon, Columns, FooterBrandContainer, FooterContainer, FooterContainerGrid, FooterContent, FooterItem, FooterTitle, TextField } from "./Footer.styles";
import { useTheme } from "@mui/material/styles";
import { ThemeProvider } from 'styled-components';
import { Link } from 'react-router-dom';

function Footer() {
    const muiTheme = useTheme();

    return (
        <FooterContainer >
            <ThemeProvider theme={muiTheme}>
                <FooterContainerGrid container justifyContent={'center'}>
                    <FooterItem item lg={4} md={4} sm={4} xs={12} >
                        {/* <FooterTitle>
                            More about us
                        </FooterTitle> */}
                        <Link style={{ textDecoration: 'none' }} to="/myCareRec"><FooterContent>
                            About Us
                        </FooterContent></Link>
                        <Link style={{ textDecoration: 'none' }} to="/contactus"><FooterContent>
                            Contact Us
                        </FooterContent></Link>
                        {/* <Link style={{textDecoration: 'none'}} to="/myCareRec"><FooterContent>
                            Career
                        </FooterContent></Link> */}
                    </FooterItem>
                    <FooterItem item lg={4} md={4} sm={4} xs={12} >
                        <FooterTitle>
                            Locations                    </FooterTitle>
                        <FooterContent>
                            Austin Texas, United States                    </FooterContent>
                    </FooterItem>
                    <FooterItem item lg={4} md={4} sm={4} xs={12}  >
                        <FooterTitle>
                            Contact
                        </FooterTitle>
                        {/* <FooterContent>
                            +1 (804) 334-0600
                        </FooterContent> */}
                        <FooterContent>
                            info@healtheserve.com
                        </FooterContent>
                    </FooterItem>
                </FooterContainerGrid>
                <FooterBrandContainer >
                <Link style={{ textDecoration: 'none', padding: '10px' }} to="/products"><BrandIcon src={MyCareRec} alt="icon"/></Link>
                    <Columns>
                        <Link style={{ textDecoration: 'none', padding: '10px' }} to="/support"><TextField>Support</TextField></Link>
                        <Link style={{textDecoration: 'none', padding: '10px'}} to="/contactus"><TextField>Contact Us</TextField></Link>
                        <TextField>Disclaimer</TextField>
                    </Columns>
                </FooterBrandContainer>
            </ThemeProvider>
        </FooterContainer>
    );
}

export default Footer;