import axios from 'axios';


export const sendSupport = ({ name, email, message }: any) => {
    axios.post('https://dev.mycarerec.com/api/v1/support', {
        name: name,
        email: email,
        message: message
    },
    {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    
    )
        .then(function (response: any) {
            console.log(response);
            return response;
        })
        .catch(function (error: any) {
            console.log(error);
            return error;
        });
}