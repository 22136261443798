import firstImg from '../../../images/firstImg.png';
import secondImg from '../../../images/secondImg.png';
import thirdImg from '../../../images/thirdImg.png';
import fourthImg from '../../../images/fourthImg.png';
import { AdvantagesContainer, SubText } from '../HomeView.styles';
import { Headings } from '../../../common/styledComponents/Headings.styles';
import { Grid, Paper } from '@mui/material';
import { makeStyles } from '@mui/styles';


const useStyles = makeStyles({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '16px',
    minHeight: '200px',
    boxShadow: 'none !important'
  },
  img: {
    width: '100%',
    marginBottom: '16px',
  },
});

const data = [
  {
    img: firstImg,
    content: '➢ Provide accurate, up-to-date, and complete health information about patients.',
    content1: '➢ Keeps track for your doctor visits'
  },
  {
    img: secondImg,
    content: '➢ Helping Doctors effectively diagnose patients, reduce errors and prescribe accurate treatments.',
    content1: '➢ Searches any health record in seconds'
  },
  {
    img: thirdImg,
    content: '➢ Integrates with doctor’s offices, insurance companies, labs, and clinics for secure data exchange.',
    content1: "➢ Enhancing privacy and security of patient and family's medical records."
  },
  {
    img: fourthImg,
    content: '➢ The ability to share personal health records anywhere at any time via mobile for better and timely  decision-making.',
    content1: '➢ Generates health trends to provide smart health advisory'
  },
];


export default function Advantages() {

  const classes = useStyles();

  return (
    <AdvantagesContainer>
      <Headings>Advantages of myCareRec</Headings>
      <Grid container spacing={2} justifyContent="center">
        {data.map((item, index) => (
          <Grid item xs={12} sm={6} md={3} key={index}>
            <Paper className={classes.paper}>
              <img className={classes.img} src={item.img} alt="" />
              <SubText variant="body1">{item.content}</SubText>
              <SubText variant="body1">{item.content1}</SubText>
            </Paper>
          </Grid>
        ))}
      </Grid>
    </AdvantagesContainer>
  );
};

