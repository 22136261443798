import styled from 'styled-components';
import { Grid } from '@mui/material';


export const MyCareRecColmGridTextContainer = styled.div`
font-family: 'poppins';
font-style: normal;
font-weight: 700;
font-size: 14px;
line-height: 20px;
max-width: 150px;
color: #263238;
font-family: poppins;
// border: 2px solid green;
// padding: 30px;
`
export const Item1 = styled.div`
// background-color: black;
// color: red;
// padding: theme.spacing(1);
padding: 30px;
`

export const Item2 = styled.div`
padding-top: 100px;
// padding-bottom: 100px;
position: relative;
`

export const Headings = styled.div`
font-family: Poppins;
font-style: normal;
font-weight: 700;
font-size: 28px;
line-height: 54px;
padding: 30px;
color: #333333;
`

export const SubHeadings = styled.div`
font-family: poppins;
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 20px;
color: #444444;
padding: 30px;
// border: 2px solid violet;
`

export const SubHeadings1 = styled.div`
font-family: poppins;
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 20px;
color: #444444;
padding: 10px 30px 10px 30px;
// border: 2px solid violet;
`

export const WhatWeDoColGrid = styled(Grid)`
display: inline-block;
font-family: poppins;
variant: overline;
// max-width: 300px;
padding: 30px !important;
// margin-right: 20px ! important;
// border: 2px solid blue;

`

export const Images = styled(Grid)`
padding-top: 50px;
    max-width: 100%;
    ${props => props.theme.breakpoints.down("md")} {
        max-width: 50%;
    }
`;
