import Footer from '../common/Footer/Footer'
import Navbar from '../common/Navbar/Navbar';
import { TCh4, TCh5 } from './t&c.styles';


const Terms = () => {
    return (
        <>
            <Navbar />
            <div style={{ margin: '50px', textDecorationColor: 'grey', wordSpacing: 3, lineHeight: 10 }}>
                <TCh5>
                    Health eServe (“Company”) is the author, publisher and owner of the internet resource at mycarerec.com with any subdomain thereof, mobile/tablet/console applications or other present or future electronic modes ("Portal").
                </TCh5><TCh5>By accessing, viewing or using the Portal or any of the content on the Portal, you indicate that you understand and intend these terms of use ("Terms of Use") to be the legal equivalent of a signed, binding written contract, and that you accept such Terms of Use and agree to be legally bound by them.
                </TCh5><TCh5>If you do not agree with the Terms of Use, you are not granted permission to use the Site and should exit immediately. Further, by impliedly or expressly accepting these Terms of Use, you also accept and agree to be bound by Our policies, including the Privacy Policy, and such other rules, guidelines, policies, terms and conditions as are relevant under the applicable law(s) in India for the purposes of accessing, browsing or transacting on the Portal, or availing any of the Services, and such rules, guidelines, policies, terms and conditions shall be deemed to be incorporated into, and considered as part and parcel of these Terms of Use.
                </TCh5>
                <TCh4>SERVICE: A Health Safe to store Health records</TCh4>
                <TCh5>A Health Safe to store Health recordsUser may use this service to manage all medical cases, appointments, and other medical records and map a digital overview of User’s medical journey.
                User agrees that usage of this Service, Portal may receive User's medical records including User's diagnostic & other lab reports and doctor prescriptions from both Third-Party Service Providers and/or directly from the User(s).
                The accuracy, integrity and the completeness of the information provided in the Health Safe depend on the information provided by User or the User authorized Third Party Service Provider.
                Portal is merely facilitator communicating or storing information and does not in any manner alter the information uploaded on the said Health Vault.
                Health eServe shall in no event be liable for any losses or injuries suffered by User or arising out of any actions or omissions made by User or any other person including a Third-Party Service Provider relying on the information provided in the Health Safe.
                </TCh5>
                <TCh4>ELIGIBILITY:</TCh4>
                <TCh5>1. User must be at least 18 (Eighteen) years of age or above and are fully able and competent to understand and agree the terms, conditions, obligations, affirmations, representations, and warranties set forth in the Agreement;
                </TCh5><TCh5>2. User have the right, authority, and capacity to enter into and to fully abide by all of the terms and conditions of this Agreement, as far as age, jurisdiction, laws of land, etc. are concerned;
                </TCh5><TCh5>3. User is not impersonating any other person and are using your actual identity; and4. User agree to comply with all applicable domestic and international laws, statutes, ordinances and regulations regarding your use of the Portal including Services.
                </TCh5>
                <TCh4>TERM AND TERMINATION:</TCh4>
                <TCh5>
                    This Agreement shall remain in full force and effect while you use the Services or are a User on the Portal. Health eServe may terminate your use of/access to the Portal or the Services (either case, in whole or part), in its sole discretion, at any time, without warning or notice to you.
                </TCh5>
                <TCh4>INTELLECTUAL PROPERTY:</TCh4>
                <TCh5>1. You may not post, modify, distribute, or reproduce in any way any copyrighted material, trademarks, or other proprietary information belonging to Health eServe, Third Party Service Provider or others (including without limitation unlicensed third-party content) without obtaining the prior written consent of the owner of such copyrighted material, trademarks, or other proprietary information.
                </TCh5><TCh5>2. If Health eServe become aware that one of our users is a repeat copyright infringer, it is our policy to take reasonable steps within our power to terminate that User. Without limiting the foregoing, if you believe that your work has been copied and posted on the Services in a way that constitutes copyright infringement, please provide us with relevant documentary and other evidence and we will be happy to take corrective action accordingly.
                </TCh5>
                <TCh4>LIMITATION OF LIABILITY:</TCh4>
                <TCh5>
                    1. In no event shall Health eServ be liable to you or any third party for any direct, indirect, consequential, exemplary, incidental, special or punitive damages, including damages for loss of use, data or profits (whether incurred directly or indirectly), any loss of goodwill or business reputation, or any other intangible losses, even if you or any third party have been advised of the possibility of such damages.
                </TCh5><TCh5>2. Notwithstanding anything to the contrary contained herein, Health eServ’s liability to you for any cause whatsoever and regardless of the form of the action, will at all times be limited to the amount paid, if any, by you to Health eServ (in the form of commission as marketplace) for the services during the term of your use. the foregoing limitation of liability shall apply to the fullest extent permitted by law. you specifically acknowledge that Health eServ shall not be liable for user content or for any defamatory, offensive, or illegal conduct of any third party and that the risk of harm or damage from the foregoing rests entirely with you.
                </TCh5>
            </div>

            <br />
                <br />
            <Footer />
        </>
    )
}

export default Terms;