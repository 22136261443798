// import { makeStyles } from '@mui/styles';


// const useStyles = makeStyles({
//     card: {
//         margin: '10px',
//         height: '100%',
//     },
//     cardContent: {
//         display: 'flex',
//         flexDirection: 'column',
//         height: '100%',
//         alignItems: 'center',
//     },
// });

// const cardsData = [
//     "Radiological Images are the results of the medical investigation tests from X Ray, CT Scans, MRI scans, etc.",
//     "Today’s medical practice increasingly depends upon the the investigative tests and the outcomes. The outcome data/ or records are  very critical for the recommendations of the future.",
//     "Today’s medical practice increasingly depends upon the the investigative tests and the outcomes. The outcome data/ or records are  very critical for the recommendations of the future.",
//     "It would also enable tracking health trends and proactive health care for the family. It would also have potential to lower the overall costs of the healthcare."
// ];


export default function Record() {

    // const classes = useStyles();


    return (
        <>
            {/* <ProductPageSecondCont>
                <center><Headings>what is electronic health record</Headings></center>
                <Grid container spacing={2} justifyContent="center">
                    {cardsData.map((content, index) => (
                        <Grid item xs={12} sm={6} md={4} lg={3} key={index} style={{ height: "auto" }}>
                            <MuiCard className={classes.card} style={{}}>
                                <CardContent className={classes.cardContent} style={{ padding: '40px 40px 20px 40px', textAlign: "center" }}>
                                    <CircleText color="textSecondary" >
                                        {content}
                                    </CircleText>
                                    <div style={{ flex: 1 }} />
                                </CardContent>
                            </MuiCard>
                        </Grid>
                    ))}
                </Grid>
            </ProductPageSecondCont> */}
        </>
    )
}