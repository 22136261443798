import Footer from "../../common/Footer/Footer";
import Advantages from "./AdvantagesSection/Advantages";
import Header from "./Header/Header";
import MyCareRecSection from "./MyCareRecSection/MyCareRec";
import WhatWeDoSection from "./WhatWeDoSection/WhatWeDoSection";

export default function HomeView() {
    return (
        <>
            <Header />
            <MyCareRecSection />
            <WhatWeDoSection />
            <Advantages />
            <Footer />
        </>
    )
}