import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import HomeView from "./components/views/HomeView/HomeView";
import MyCareRec from "./components/views/MyCareRecView/MyCareRec ";
import ContactUs from "./components/views/ContactUs";
import AboutUsView from './components/views/AboutUsView/AboutUsView';
import Support from './components/views/Support';
import Terms from './components/views/termsAndCondition';

function App() {
  return (
    <>
    <BrowserRouter>
      <Routes>
          <Route index element={<HomeView />} />
          <Route path="myCareRec" element={<AboutUsView />} />
          <Route path="products" element={<MyCareRec />} />
          <Route path="contactus" element={<ContactUs />} />
          <Route path="/support" element={<Support />} />
          <Route path="/termsAndConditions" element={<Terms />} />
      </Routes>
    </BrowserRouter>
    </>
  );
}



export default App;
