import styled from 'styled-components';

export const Container = styled.div`
 background-color: transparent !important;
 display: inline-block !imporatnt;
`

export const Tabs = styled.div`
color: #818181;
padding: 30px; 
fontWeight: 800; 
font-family: poppins; 
font-size: 18px;
cursor: pointer;
display: inline;
`