import { TextField, Grid } from '@mui/material';
import { useState } from 'react';
import { sendSupport } from '../api/support';
import Footer from '../common/Footer/Footer'
import Navbar from '../common/Navbar/Navbar';
import { Headings } from '../common/styledComponents/Headings.styles';
import ContactPageImg from '../images/ContactPageImg.png';
import { ContactPageImageContainer, SubmitBtn } from '../views/ContactUs.styles';


const ContactUs = () => {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");

    const handleSubmit = (event: any) => {
        event.preventDefault();
        sendSupport({ name, email, message });
        setName("");
        setEmail("");
        setMessage("");
    }
    return (
        <>
            <Navbar />
            <ContactPageImageContainer imgUrl={ContactPageImg}>
                <Grid container spacing={2}>
                    <Grid item xs={10} sm={5} md={6} lg={6} style={{ backgroundColor: "white", borderRadius: "10px", padding: "50px", margin: "30px 0px 0px 100px" }}>
                        <center><Headings>Get in touch</Headings></center>
                        <form>
                            <Grid item  >
                                <TextField
                                    fullWidth
                                    required
                                    // id="name"
                                    label="Name"
                                    name="userName"
                                    margin="normal"
                                    style={{ color: "none !important" }}
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                />
                            </Grid>
                            <Grid item>
                                <TextField
                                    fullWidth
                                    required
                                    type='email'
                                    label="Email"
                                    name="email"
                                    margin="normal"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                            </Grid>
                            <Grid item>
                                <TextField
                                    fullWidth
                                    required
                                    label="Message"
                                    name="message"
                                    margin="normal"
                                    multiline
                                    value={message}
                                    onChange={(e) => setMessage(e.target.value)}
                                />
                            </Grid>
                            <br />
                        </form>
                        <center><div><SubmitBtn onClick={handleSubmit}>Submit</SubmitBtn></div></center>
                    </Grid>
                    <Grid item xs={12} sm={5} md={7} lg={7}>
                    </Grid>
                </Grid>
            </ContactPageImageContainer>
            <Footer />
        </>
    )
}

export default ContactUs;